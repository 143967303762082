import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import stone from '../assets/stoneSticky2.png';
import stone2 from '../assets/stoneSticky2.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div>
            <div class="aboutMain">
                <div>
                    <div class="conT">WTF IS MEMEMON?</div>
                    <p class="conT2"><span className='conT2-HL'>What the shell is MeMeMon?</span> Imagine a universe where your laughter is the currency and the jokes are the investment. MeMeMon is where the turtle-power meets blockchain, in a dazzling display of hilarity and high returns!</p>
                    <p class="conT2">MeMeMon isn’t just another meme coin; it's a ticket to a sidesplitting, shell-cracking party on the Solana blockchain. Picture this: you, reclining on a virtual beach, sipping on digital piña coladas, while your MeMeMon does the heavy lifting, turning chuckles into Solana – and Solana into $MMON.</p>
                    <p class="conT2">Ready to giggle your way to the gains? MeMeMon is no joke when it comes to fun finance. Join us, and let the LOLs lead you to wealth!</p>
                </div>
            </div>

            <div className='flexStone'>
            <img className='stoneS' src={stone}/>
            <img className='stoneS2' src={stone2}/>
            </div>
            </div>
        )
    }
}

export default About;