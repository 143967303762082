import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import shell1 from '../assets/shell1.png';
import shell2 from '../assets/shell2.png';


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Intro extends Component {

    render() {
        return (

            <div className='blueBg'>
            <div class="aboutMain--2">
                <p class="conT2">Welcome to the wild and whimsical world of MeMeMon, where finance meets fun in the innovative landscape of Solana! Our charming, cheeky turtles are here not just to steal a laugh, but to offer you an opportunity to grow your portfolio on the fast and efficient Solana blockchain.</p>
                <p class="conT2">Join our community of jesters and geniuses where you can send your $SOL to secure your share of $MMON tokens. Fasten your seatbelts for a rollicking ride through the realm of crypto – where the memes are as solid as the technology behind them!</p>
                <p class="conT2">Ready for a chuckle and a chance? Dive into the world of MeMeMon and let the good times and gains roll!</p>
            </div>

            <div className='shells2'>

            <img className='shell1' src={shell1}/>
            <img className='shell2' src={shell2}/>

            </div>
            </div>
        )
    }
}

export default Intro;

